import { render, staticRenderFns } from "./TenantEdit.vue?vue&type=template&id=72b63a1c&scoped=true&"
import script from "./TenantEdit.vue?vue&type=script&lang=js&"
export * from "./TenantEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72b63a1c",
  null
  
)

export default component.exports